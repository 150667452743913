import React from "react";
import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import AniLink from "gatsby-plugin-transition-link/AniLink";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
import CallToAction from "../components/Repeating/CTA";
import Accolades from "../components/Repeating/Accolades";
import ButtonSolid from "../components/Button/ButtonSolid";

const Page = ({ data }) => {
  return (
    <Layout>
      <SearchEngineOptimization
        title="Locations | Colorado Lawyers | JBP"
        description="At Jorgensen, Brownell & Pepin, P.C., our attorneys assist clients in Broomfield, Longmont, and Loveland. Call us today to discuss your case."
        openGraphImage={data.openGraphImage.publicURL}
        twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      <section className="pt-10 pb-20 md:pb-28">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-8 md:gap-x-14 lg:gap-x-28 items-center">
            <div className="order-2 md:order-1">
              <h1>Our Locations</h1>
              <p>
                At Jorgensen, Brownell & Pepin, P.C., we are proud to provide
                high-quality legal services to individuals in the Denver Metro
                Area and throughout Northern Colorado. Whether we are addressing
                a personal injury, criminal defense, or family law matter, we do
                everything possible to obtain the most favorable case results.
                We have become known for our relentless determination and
                strategic problem-solving. We are passionate when it comes to
                protecting the rights and interests of the men and women we
                represent.
              </p>

              <div className="space-y-4 flex flex-col mb-6 relative">
                <div className="relative">
                  {/* <span className="absolute px-[10px] left-[95px] top-[15px] bg-primary-600 rounded-[8px] font-semibold uppercase">
                     new!
                   </span> */}
                </div>
                <AniLink
                  fade
                  to="/denver/"
                  className="text-primary-600 font-semibold"
                >
                  <i className="fas fa-map-marker-alt  text-xl mr-2.5"></i>
                  Denver
                </AniLink>

                <AniLink
                  fade
                  to="/broomfield/"
                  className="text-primary-600 font-semibold"
                >
                  <i className="fas fa-map-marker-alt  text-xl mr-2.5"></i>{" "}
                  Broomfield
                </AniLink>

                <AniLink
                  fade
                  to="/longmont/"
                  className="text-primary-600 font-semibold"
                >
                  <i className="fas fa-map-marker-alt  text-xl mr-2.5"></i>{" "}
                  Longmont
                </AniLink>

                <AniLink
                  fade
                  to="/loveland/"
                  className="text-primary-600 font-semibold"
                >
                  <i className="fas fa-map-marker-alt  text-xl mr-2.5"></i>{" "}
                  Loveland
                </AniLink>
              </div>

              <ButtonSolid
                modal="modal-contact"
                text="Contact Us"
                className="w-full md:w-auto"
              />
            </div>
            <div className="order-1 md:order-2">
              <GatsbyImage image={data.hero.childImageSharp.gatsbyImageData} />
            </div>
          </div>
        </div>
      </section>

      <section className="pb-20 md:pb-18">
        <div className="container">
          <div className="grid md:grid-cols-2 gap-y-10 md:gap-x-10 lg:gap-x-20 items-center">
            <div>
              <GatsbyImage image={data.map.childImageSharp.gatsbyImageData} />
            </div>
            <div>
              <AniLink fade to="/denver/" className="group">
                <div className="inline-flex flex-row space-x-6 items-center mb-10">
                  <div className="overflow-hidden">
                    <GatsbyImage
                      image={data.denver.childImageSharp.gatsbyImageData}
                      className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>

                  <div>
                    <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                      Denver Office
                    </div>
                    <address className="not-italic">
                      <a
                        href="https://goo.gl/maps/4xirs8pC3yyd7qNF7"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        3461 Ringsby Ct. Suite 350
                        <br></br>
                        Denver, CO 80216
                      </a>
                    </address>
                    <a
                      href="tel:3036780560"
                      className="text-primary-50 hover:text-primary-600"
                    >
                      (303) 678-0560
                    </a>
                  </div>
                </div>
              </AniLink>

              <AniLink fade to="/broomfield/" className="group">
                <div className="inline-flex flex-row space-x-6 items-center mb-10">
                  <div className="overflow-hidden">
                    <GatsbyImage
                      image={data.broomfield.childImageSharp.gatsbyImageData}
                      className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>

                  <div>
                    <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                      Broomfield Office
                    </div>
                    <address className="not-italic">
                      <a
                        href="https://goo.gl/maps/4xirs8pC3yyd7qNF7"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        8001 Arista Pl. Suite 415
                        <br /> Broomfield, CO 80021
                      </a>
                    </address>
                    <a
                      href="tel:970-304-0075"
                      className="text-primary-50 hover:text-primary-600"
                    >
                      (970) 304-0075
                    </a>
                  </div>
                </div>
              </AniLink>

              <AniLink fade to="/longmont/" className="group">
                <div className="inline-flex flex-row space-x-6 items-center mb-10">
                  <div className="overflow-hidden">
                    <GatsbyImage
                      image={data.longmont.childImageSharp.gatsbyImageData}
                      className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>

                  <div>
                    <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                      Longmont Office
                    </div>
                    <address className="not-italic">
                      <a
                        href="https://goo.gl/maps/DxZwHWW6xLyDTrDD9"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        900 S Main St. Suite 100
                        <br /> Longmont, CO 80501
                      </a>
                    </address>
                    <a
                      href="tel:720-491-3117"
                      className="text-primary-50 hover:text-primary-600"
                    >
                      (720) 491-3117
                    </a>
                  </div>
                </div>
              </AniLink>

              <AniLink fade to="/loveland/" className="group">
                <div className="flex flex-row space-x-6 items-center">
                  <div className="overflow-hidden">
                    <GatsbyImage
                      image={data.loveland.childImageSharp.gatsbyImageData}
                      className="transform scale-100 md:group-hover:scale-105 filter blur-none transition-all duration-500 ease-linear"
                    />
                  </div>

                  <div>
                    <div className="font-heading text-lg text-secondary-50 font-extrabold mb-1.5">
                      Loveland Office
                    </div>
                    <address className="not-italic">
                      <a
                        href="https://goo.gl/maps/qsKJarM8tyr9KKMb9"
                        target="_blank"
                        rel="noreferrer"
                        className="text-primary-50 hover:text-primary-600"
                      >
                        5285 McWhinney Blvd. Suite 100
                        <br /> Loveland, CO 80538
                      </a>
                    </address>
                    <a
                      href="tel:970-644-6275"
                      className="text-primary-50 hover:text-primary-600"
                    >
                      (970) 644-6275
                    </a>
                  </div>
                </div>
              </AniLink>
            </div>
          </div>
        </div>
      </section>

      <CallToAction />
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Locations.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Locations.jpg" }
    ) {
      publicURL
    }
    hero: file(relativePath: { eq: "locations/hero.png" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 588)
      }
    }
    denver: file(relativePath: { eq: "modal/denver.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    broomfield: file(relativePath: { eq: "modal/broomfield.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    longmont: file(relativePath: { eq: "modal/longmont.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    loveland: file(relativePath: { eq: "modal/loveland.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 160)
      }
    }
    map: file(relativePath: { eq: "locations/2.0 Map.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED, width: 560)
      }
    }
  }
`;

export default Page;
